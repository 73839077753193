@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100;0,300;0,400;0,700;1,400&family=Bungee&family=Bungee+Outline&display=swap');

body {
    background-color: white;
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
}

.site {
    opacity: 1;
    animation-name: siteTrans;
    animation-duration: 1.5s;

}

@keyframes siteTrans {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.loading {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    animation-name: loadingAnim;
    animation-duration: 1s;
    opacity: 1;
}

@keyframes loadingAnim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.landingText01 {
    font-family: 'Bungee', cursive;
    color: rgb(14, 14, 14);
    position: absolute;
    top: 20%;
    right: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    h3 {
        line-height: 18px;
        padding-right: 3%;
        font-size: 12pt;
    }
}

.landingTextWelcome {
    font-family: 'Bungee', cursive;
    color: rgb(255, 0, 0);
    position: absolute;
    top: 80%;
    left: 5%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    z-index: 30;

    h3 {
        line-height: 18px;
        padding-right: 3%;
        font-size: 22pt;
        line-height: 30px;
    }
}

.landingText02 {
    font-family: 'Bungee Outline', cursive;
    color: rgb(14, 14, 14);
    position: absolute;
    top: 28%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: space-between;

    h3 {
        left: 100%;
        line-height: 100%;
        font-size: 10vw;
        font-weight: 200;
        white-space: nowrap;
        animation: moveText 10s linear infinite;
        flex-shrink: 0;
    }

    @keyframes moveText {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-100%);
        }
    }
}

.landingText03 {
    font-family: 'Bungee Outline', cursive;
    color: rgb(14, 14, 14);
    position: absolute;
    top: 45%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: space-between;

    h3 {
        left: 100%;
        line-height: 100%;
        font-size: 10vw;
        font-weight: 200;
        white-space: nowrap;
        animation: moveText2 10s linear infinite;
        flex-shrink: 0;
    }

    @keyframes moveText2 {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(0%);
        }
    }
}

.landingIMG {
    position: fixed;
    width: 50%;
    height: 100vh;
    z-index: 3;
    margin: 0;
    padding: 0;
    background-image: url('../public/img/landingPagePic03.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    img {

        object-fit: cover;
    }
}

.nav {
    position: absolute;
    width: 48%;
    z-index: 19;
    right: 0%;

    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 30px;
    padding-top: 10px;

    margin-top: 1%;
    border: solid rgb(14, 14, 14) 1px;
    border-right: 0px;
    border-top-left-radius: 100px;
    white-space: normal;

    .menuButton {
        display: none !important;
    }


    h4 {
        font-size: 1.5vw;
        align-self: flex-start;
        color: rgb(14, 14, 14);
        font-family: 'Azeret Mono', monospace;
        font-weight: 300;

        a {
            text-decoration: none;
            color: inherit;
        }

        a:hover {
            color: rgb(128, 255, 0);
        }
    }
}

.exhibModifier {
    z-index: 10 !important;
    width: 93% !important;
    right: 0 !important;
    margin: 3%;
    top: 4% !important;
    border: none !important;
    background-color: transparent !important;
    padding-bottom: 10%;
}

.exhibitions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 250%;
    width: 93%;
    right: 4%;
    border: 2px solid rgb(14, 14, 14);
    border-bottom: 0;
    font-family: 'Azeret Mono', monospace;
    z-index: 22;
    background-color: white;

    button {
        background-color: transparent;
        color: rgb(14, 14, 14);
        border: 0;
        border-bottom: 2px black solid;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 2.5vw;
        font-weight: 100;
        text-align: right;
        padding-right: 15%;
    }

    .btn-secondary:focus {
        box-shadow: none;
        background-color: transparent;
    }

    .btn-secondary:hover {
        background-color: transparent;
        color: inherit;
    }

    .exhibition {
        align-items: center;
        padding-top: 2%;
        border-bottom: 2px black solid;
        padding-bottom: 5%;

        h1 {
            text-align: center;
        }

        h3 {
            margin-left: 10%;
            margin-top: 3%;
        }

        .exhib {
            width: 100%;
            font-size: 1vw;
            margin-bottom: 1%;

            .row {

                div {
                    display: flex;
                    align-items: center;
                    margin: 5px;
                    border: 1px rgb(14, 14, 14) solid;
                    padding: 1%;
                }
            }
        }

    }

}

.noBottomBorder {
    border-bottom: 0px !important;
}

.news {
    position: absolute;
    top: 130%;
    width: 100%;
    height: 45vh;
    background-color: white;
    z-index: 22;
    border: 2px rgb(14, 14, 14) solid;
    border-left: 0;
    border-right: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    button {
        width: 30%;
    }

    .post {
        width: 40%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-family: 'Azeret Mono', monospace;
        padding: 1%;
        border: 1px black solid;

        h4 {
            font-size: 1.3vw;
            font-family: 'Bungee', cursive;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

    }
}

.contact {
    position: absolute;
    z-index: 1;
    top: 270%;
    width: 100%;
    height: 100vh;
    background:
        linear-gradient(90deg, rgba(0, 0, 0, 0.488), rgba(255, 255, 255, 0)),
        url(https://grainy-gradients.vercel.app/noise.svg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 3%;
    font-family: 'Bungee', cursive;


    color: rgba(77, 77, 77, 0.626);

    h2 {
        font-size: 2.5vw;
    }

    .email {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        z-index: 99;
    }

    .fadeIn {
        opacity: 1;
    }
}

.backButton {
    width: 15%;
    border-top-right-radius: 100px;
    background-color: transparent;
    text-align: center;
    color: rgb(0, 0, 0);
    position: absolute;
    border: solid rgb(14, 14, 14) 1px;
    left: -1%;
    margin-top: 1%;
    padding-top: 2px;
    padding-bottom: 8px;
    font-size: 1.5vw;
}

.aboutCollections {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Azeret Mono', monospace;
    padding: 2%;

    button {
        width: 35%;
        margin-top: -3%;
        margin-bottom: 5%;
        background-color: transparent;
        border: none;
        text-align: left;
        color: rgb(88, 88, 88);
        font-size: 18pt;
    }

    h1 {
        width: 47%;
    }
}

.background01 {
    filter: contrast(590%) brightness(1100%);
    background:
        radial-gradient(circle, rgba(255, 0, 34, 0.488), rgba(255, 255, 255, 0)),
        url(https://grainy-gradients.vercel.app/noise.svg);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
}

.background02 {
    filter: contrast(590%) brightness(1100%);
    background:
        linear-gradient(180deg, rgba(30, 0, 255, 0.948), rgba(255, 255, 255, 0)),
        url(https://grainy-gradients.vercel.app/noise.svg);
    width: 100%;
    height: 80vh;
    position: absolute;
    z-index: -1;

    h1 {
        color: white;
        font-family: 'Bungee', cursive;
        font-size: 3.5vw;
    }
}

.margin-01 {
    padding-top: 10%;
}

.margin-02 {
    margin-bottom: 20%;
}

.background03 {
    filter: contrast(590%) brightness(1100%);
    background:
        linear-gradient(180deg, rgba(0, 255, 8, 0.383), rgba(255, 255, 255, 0)),
        url(https://grainy-gradients.vercel.app/noise.svg);
    width: 100%;
    height: 80vh;
    position: absolute;
    z-index: -1;
}

.variant {
    background:
        radial-gradient(circle, rgba(0, 255, 17, 0.488), rgba(255, 255, 255, 0)),
        url(https://grainy-gradients.vercel.app/noise.svg);
}

.albumContainer {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    z-index: 99;

    .album {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 30%;

        .image-container {
            display: flex;
            width: 100%;
            height: 40vh;
            overflow: hidden;
            border-top-left-radius: 25%;
            position: relative;

            .loading-animation {
                background: #ccc;
                /* Background color for the loading animation */
                /* Add your loading animation styles here */
            }

            .hidden {
                opacity: 0;
                transition: opacity 1.5s ease;
                /* Adjust the duration and easing as needed */
            }

            .visible {
                opacity: 1;
                transition: opacity 1.5s ease;
            }

            .decor {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                position: absolute;
                border-top-left-radius: 25%;
                width: 93%;
                height: 37vh;
                border: rgb(255, 255, 255) 3px solid;
                z-index: 20;
                margin: 3%;

                .inside {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    width: 90%;
                    height: 11vh;
                    border-bottom: white 3px solid;
                    text-align: end;

                    h1 {
                        color: white;
                        font-size: 1.5vw;
                        margin-right: 10px;
                    }

                    h1:hover {
                        color: #ff8a00;
                    }
                }

                .inside2 {
                    height: 100%;
                    width: 100%;
                    padding: 1%;
                    opacity: 0;
                    transition: 0.5s ease-in-out;
                    background-color: #ffffffaa;

                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;

                    p {
                        font-size: 1vw;
                        color: black;
                    }
                }

                .inside2:hover {
                    opacity: 1;
                }

            }

            a {
                width: 100%;
                display: flex;

                img {
                    width: 100%;
                    object-fit: cover;
                    filter: grayscale(100%);
                }
            }

        }


        h1 {
            font-family: 'Azeret Mono', monospace;
            font-size: 3vw;
        }

        p {
            font-family: 'Azeret Mono', monospace;
            font-size: 1.5vw;
        }

    }
}

.soloAlbumContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;

    .pictures {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        gap: 10px;

        .picture {

            display: flex;
            width: 20%;
            height: 30vh;
            overflow: hidden;


            .loading-animation {
                background: #ccc;
                /* Background color for the loading animation */
                /* Add your loading animation styles here */
            }

            .hidden {
                opacity: 0;
                transition: opacity 1.5s ease;
                /* Adjust the duration and easing as needed */
            }

            .visible {
                opacity: 1;
                transition: opacity 1.5s ease;
            }

            img {
                height: 60vh;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

.gallery-overlay {
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    transition: opacity 1s ease, visibility 1s ease;
    visibility: hidden;
    pointer-events: none;

    .gallery-close {
        cursor: pointer;
        font-family: 'Bungee', cursive;
        padding-left: 10px;
        padding-top: 10px;
        color: #ff8a00;
    }

    .image-gallery {
        .image-gallery-thumbnails-container {
            img {
                height: 100%;
            }
        }

        .image-gallery-content {
            .image-gallery-slide-wrapper {
                height: 75vh;

                img {
                    height: 75vh;
                }
            }
        }
    }

}

.gallery-open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: opacity 1s ease, visibility 1s ease;
}

.quitModif {
    font-family: 'Bungee', cursive !important;
    color: white !important;
    top: 20%;
}

.proposer {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;

    .right {
        width: 60%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-family: 'Azeret Mono', monospace;
        color: rgb(12, 12, 12);

        a {
            color: inherit;
        }

    }

    .left {
        width: 40%;
        padding-right: 3%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-top: 5%;

        .image-container {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-top: 15%;

            img {
                width: 60%;
            }
        }

    }
}

.posts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 5% 5% 0 10%;
    flex-wrap: wrap;
    gap: 5%;
    width: 100%;
    margin-top: 7%;


    .post {
        width: 40%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-family: 'Azeret Mono', monospace;
        padding: 1%;
        border: 1px black solid;
        margin-bottom: 5%;

        h4 {
            font-size: 1.3vw;
            font-family: 'Bungee', cursive;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

    }
}

.post {
    font-family: 'Azeret Mono', monospace;
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        color: white;
        margin-bottom: 3%;
    }

    img {
        width: 30%;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    h5 {
        align-self: flex-start;
    }

    p {
        text-align: justify;
    }
}

.footer {
    width: 100%;
    height: 20vh;
    background-color: rgb(255, 255, 255);
    z-index: 22;
    position: fixed;
    margin: 0;
    bottom: -20vh;
    padding: 1%;

    .credits {
        border: rgb(51, 51, 51) 3px solid;
        height: 100%;
        color: rgb(51, 51, 51);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Azeret Mono', monospace;
        font-size: 1.5vh;
    }

}

.footerShow {
    animation-name: footerMove;
    animation-duration: 0.3s;
    bottom: 0;
}

@keyframes footerMove {
    0% {
        bottom: -20%;
    }

    100% {
        bottom: 0;
    }
}

.footerHide {
    animation-name: footerMove2;
    animation-duration: 0.3s;
    bottom: -20%;
}

@keyframes footerMove2 {
    0% {
        bottom: 0;
    }

    100% {
        bottom: -20%;
    }
}

.new-position {
    position: relative !important;
}


//animation part

.arrow01 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 20%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 1s;
}

.arrow02 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 35%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 2s;
}

.arrow03 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 50%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 3s;

}

.arrow04 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 65%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 4s;

}

.arrow05 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 80%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 5s;

}

.arrow06 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 95%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 6s;

}

.arrow07 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 110%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 7s;

}

.arrow08 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 125%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 8s;

}

.arrow09 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 140%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 9s;

}

.arrow10 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 155%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 10s;

}

.arrow11 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 170%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 11s;

}

.arrow12 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 185%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 12s;

}

.arrow13 {
    stroke: #ff8a00;
    width: 15%;
    position: absolute;
    top: 200%;
    right: 25%;
    animation: colorAnimation 13s infinite;
    animation-delay: 13s;

}

@keyframes colorAnimation {
    0% {
        stroke: rgb(8, 0, 255);
    }

    100% {
        stroke: #ff8a00;
    }
}

.movePic {
    animation-name: landingIMGMove;
    animation-duration: 0.3s;
    margin-top: -10%;
}

.moveBackPic {
    animation-name: landingIMGMove2;
    animation-duration: 0.3s;
    margin-top: 0%;
}

@keyframes landingIMGMove2 {
    0% {
        margin-top: -10%;
    }

    100% {
        margin-top: 0%;
    }
}

@keyframes landingIMGMove {
    0% {
        margin-top: 0%;
    }

    100% {
        margin-top: -10%;
    }
}

@keyframes menuAnimation01 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes menuAnimation02 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-180deg);
    }
}

.showMenu {
    right: -5px !important;
    transition: ease-in-out 1s;
}

.menuAnimation {
    animation: menuAnimation01 1s !important;
}

// responsive media querys

@media only screen and (max-width: 1580px) {
    .exhibModifier {
        padding-bottom: 15%;
    }
}

@media only screen and (max-width: 1200px) {
    .backButton {
        font-size: 10pt;
        left: -1% !important;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .albumContainer {
        padding-bottom: 10%;
        .album {
            width: 45% !important;
            height: 30vh;

            .image-container {
                .decor {
                    .inside {
                        h1 {
                            font-size: 18pt;
                        }
                    }
                }
            }
        }
    }

    .soloAlbumContainer {

        padding-bottom: 20%;

        .aboutCollections {
            h1 {
                font-size: 1.5rem;
                font-weight: bold;
            }

            p {
                font-size: 9pt;
            }
        }

        .pictures {
            .picture {
                width: 20% !important;
                height: 10vh !important;

                .image-container {

                    img {
                        height: 20vh !important;
                        object-fit: cover;
                    }
                }
            }
        }

    }

    .exhibModifier {
        padding-bottom: 20% !important;
    }

}

@media only screen and (max-width: 950px) {
    .exhibModifier {
        padding-bottom: 35% !important;

        .exhib {
            
            margin-bottom: 5% !important;
            .row {
                font-size: 12pt;
            }
        }
    }
}

@media only screen and (max-width: 850px) {

    .backButton {
        margin-top: 1%;
        border-top-right-radius: 0;
        width: 18%;
        font-size: 12pt;
        height: 2.5vh;
        text-align: center;
        left: -3%;
    }

    .news {
        top: 100%;
    }

    .arrow01,
    .arrow02,
    .arrow03,
    .arrow04,
    .arrow05,
    .arrow06,
    .arrow07,
    .arrow08,
    .arrow09,
    .arrow10,
    .arrow11,
    .arrow12,
    .arrow13 {
        width: 25%;
        right: 20%;
    }

    .arrow01 {
        top: 15%;
    }

    .arrow02 {
        top: 26%;
    }

    .arrow03 {
        top: 37%;
    }

    .arrow04 {
        top: 48%;
    }

    .arrow05 {
        top: 59%;
    }

    .arrow06 {
        top: 70%;
    }

    .arrow07 {
        top: 81%;
    }

    .arrow08 {
        top: 92%;
    }

    .arrow09 {
        top: 103%;
    }

    .arrow10 {
        top: 114%;
    }

    .arrow11 {
        top: 125%;
    }

    .arrow12 {
        top: 136%;
    }

    .arrow13 {
        top: 146%;
    }

    .landingText02 {
        top: 35%;
    }

    .exhibModifier {
        padding-bottom: 40% !important;
    }

    .exhibitions {
        top: 175%;

        .exhibition {
            h1 {
                font-size: 20pt;
                text-align: center;
                margin-bottom: 1%;
            }

            .exhib {
                font-size: 1.5vw !important;

                .row {
                    background-color: transparent;

                    div {
                        display: flex;
                        align-items: center;
                        margin: 5px;
                        background-color: transparent;
                        border: 1px solid black;
                    }
                }
            }

        }
    }

    .contact {
        top: 210%;
        padding-bottom: 25%;
    }

    .nav {
        gap: 10px;
    }

    .landingText02 {
        top: 37%;

        h3 {
            font-size: 15vw !important;
        }
    }

    .landingText03 {
        top: 46%;

        h3 {
            font-size: 15vw !important;
        }
    }

    .inside2:hover {
        pointer-events: visible;

        p {
            font-size: 2vw !important;
        }
    }

    .site {
        height: 130vh;
    }

    .albumContainer {
        padding: 2%;
        height: 75vh;
        position: absolute;
        overflow: auto;

        .album {
            width: 48% !important;

            .image-container {
                img {
                    width: 100% !important;
                }
            }
        }
    }

    .aboutCollections {
        padding-top: 8% !important;
    }

    .news {
        .post {
            h4 {
                font-size: 2.2vw;
            }
        }
    }

    .pictures {

        margin-bottom: 35% !important;

        .picture {
            width: none;
            display: flex;
            align-items: center;

            img {
                width: 100% !important;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .post {
        padding-bottom: 35%;

        h4 {
            font-size: 14pt !important;
        }
    }
    
    .posts {
        padding-bottom: 35%;
        padding-left: 5%;
    }


}

@media only screen and (max-width: 550px) {
    .backButton {
        margin-top: 5%;
        border-top-right-radius: 0;
        width: 18%;
        height: 5vh;
        font-size: 15pt;
    }

    .cls-1 {
        stroke-width: 20px !important;
    }

    .exhibModifier {
        padding-bottom: 45% !important;
    }

    .exhibition {
        padding: 2%;
        padding-top: 10% !important;

        h3 {
            padding-top: 5%;
            font-size: 18pt;
            text-align: center !important;
        }

        .row {
            font-size: 9pt;
            padding: 2%;
            background-color: #fff !important;

            div {
                background-color: #f2f2f2 !important;
                border: none !important;
            }
        }
    }

    .menuButton {
        width: 50px;
        height: 50px;
        display: flex !important;
        flex-direction: column;
        gap: 5px;
        position: fixed;
        right: 15px;
        top: 15px;
        z-index: 99;
        animation: menuAnimation02 1s;

        .menuPart01 {
            width: 100%;
            height: 33%;
            border: black solid 1px;
            background-color: #fff;
        }

        .menuPart02 {
            width: 100%;
            height: 33%;
            border: black solid 1px;
            background-color: #fff;
        }

        .menuPart03 {
            width: 100%;
            height: 33%;
            border: black solid 1px;
            background-color: #fff;
        }
    }

    .nav {
        width: 100%;
        z-index: 89;
        background-color: #fff;
        height: 50%;
        display: flex;
        flex-direction: column;
        position: fixed;
        right: -100%;
        transition: ease-in-out 1s;
        border-top-left-radius: 0;
        border-bottom-left-radius: 20%;

        h4 {
            font-size: 1rem;
            text-align: center;
            width: 100%;
        }
    }

    .landingIMG {
        width: 100%;
        z-index: 10;
    }

    .landingText01 {
        color: #ff0000;
        z-index: 88;
    }

    .exhibitions {
        width: 95%;
        background-color: #fff;
        z-index: 88;

    }

    .contact {
        z-index: 78;

        .email {
            font-size: 1.5rem;
        }

    }

    .albumContainer {
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: wrap !important;
        height: 50vh;
        z-index: 54;
        padding-left: 2%;

        .album {
            width: 95% !important;
            margin-bottom: 20px;
            height: 100vh;

            a {
                width: 100% !important;
                display: flex;

                img {
                    width: 100% !important;
                    object-fit: cover;
                    filter: grayscale(100%);
                }

                h1 {
                    font-size: 1rem !important;
                }

                .inside2 {
                    p {
                        font-size: 1.5rem !important;
                    }
                }
            }
        }

    }

    .footer {
        z-index: 99;
    }

    svg {
        z-index: 50;
    }

    .news {
        z-index: 51;

        .post {
            padding: 2%;
            border-bottom-left-radius: 10%;
            border-bottom-right-radius: 10%;

            p {
                font-size: 0.6rem;
            }

            h4 {
                font-size: 10pt !important;
            }

            h5 {
                font-size: 0.5rem;
            }
        }
    }

    .proposer {

        .right {

            justify-content: center;

            h5 {
                font-size: 1rem;
            }

            h4 {
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }

    .posts {
        padding-bottom: 50%;
        flex-direction: column;

        .post {
            width: 100%;

            h4 {
                font-size: 1rem;
                text-align: center;
            }
        }
    }

    .contact {
        top: 260% !important;
        align-items: center;
        padding-bottom: 30%;

        h2 {
            font-size: 1rem !important;
            text-align: left;
            color: #ffffff;
        }
    }

    .exhibitions {

        right: 2.5%;

        button {
            font-size: 1rem;
        }
    }

    .soloAlbumContainer {

        padding-bottom: 20%;
        padding-top: 15%;

        .aboutCollections {
            h1 {
                width: 100%;
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center !important;            }

            p {
                font-size: 9pt;
            }
        }

        .pictures {
            .picture {
                width: 40% !important;
                height: 20vh !important;

                .image-container {

                    img {
                        height: 30vh !important;
                        object-fit: cover;
                    }
                }
            }
        }

    }

    .post {
        padding-bottom: 40%;
        padding-top: 15%;

        h1 {
            font-size: 2rem;
        }

        h5 {
            font-weight: bold;
        }

        p {
            font-size: 12px;
        }

        img {
            width: 95%;
            margin-right: 10px;
        }
    }

    .footer {
        .credits {
            text-align: center;
        }
    }


}